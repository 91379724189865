import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "icheck/skins/all.css";
import AuthContext from "./context/auth-context";

import Cookies from "js-cookie";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Cargando...</div>
);

// Containers
const DefaultLayout = React.lazy(() =>
  import("./containers/DefaultLayout/DefaultLayout")
);

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const ResetPassword = React.lazy(() => import("./views/Pages/ResetPassword"));
// const Register = React.lazy(() => import('./views/Pages/Register'));
// const Page404 = React.lazy(() => import('./views/Pages/Page404'));
// const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {
  state = {
    token: null,
    userId: null,
    userType: null,
    regionId: null,
    sessionId: null,
    csrftoken: null,
    userName: null,
    userTypes: null,
    groups: null,
  };

  componentDidMount() {
    // Get session cookies of django
    const sessionId = Cookies.get("sessionid");
    const csrftoken = Cookies.get("csrftoken");

    /* #region  check sessionId is valid */
    if (sessionId && csrftoken) {
      fetch("/api/users/check", {
        method: "GET",
        headers: {
          sessionid: sessionId,
          csrftoken: csrftoken,
        },
      })
        .then((res) => {
          if (res.status !== 200 && res.status !== 201) {
            this.setState({
              alertMessage: "Autentificación fallida.",
              showAlert: true,
            });
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.token) {
            console.log("session valid", resData.data);
            this.login(
              resData.token,
              resData.data.id,
              resData.timeExp,
              parseInt(resData.type),
              resData.data.region ? resData.data.region : 1,
              resData.sessionid ? resData.sessionid : null,
              resData.csrftoken ? resData.csrftoken : null,
              resData.data.username ? resData.data.username : null,
              resData.data.groups && resData.data.groups.length
                ? resData.data.groups.map((g) => g.name).join()
                : "",
              resData.data.groups && resData.data.groups.length
                ? resData.data.groups.map((g) => g.name)
                : []
            );

            setTimeout(() => {
              this.logout();
              window.location = process.env.REACT_APP_URL_ACCESS;
            }, resData.timeOut * 1000);
          } else {
            console.log("session NOT ok");
            window.location = process.env.REACT_APP_URL_ACCESS;
          }
        })
        .catch((error) => {
          console.log("SESSION CHECK FAILED: ", error);
          window.location = process.env.REACT_APP_URL_ACCESS;
        });
    } else {
      window.location = process.env.REACT_APP_URL_ACCESS;
    }
  }

  login = (
    token,
    userId,
    timeExp,
    userType,
    regionId,
    sessionId,
    csrftoken,
    userName,
    userTypes,
    groups
  ) => {
    localStorage.setItem("session", token);
    localStorage.setItem("id", userId);
    localStorage.setItem("name", userName);
    localStorage.setItem("type", userType);
    localStorage.setItem("types", userTypes);
    localStorage.setItem("timeExp", timeExp);
    localStorage.setItem("regionId", regionId);
    localStorage.setItem("groups", groups);

    // set django session cookies
    const cookiesConfig = {
      path: "/",
      domain: "enccrv.cl",
      secure: false,
      sameSite: "Lax",
    };

    Cookies.set("sessionid", sessionId, cookiesConfig);
    Cookies.set("csrftoken", csrftoken, cookiesConfig);

    this.setState({
      token: token,
      userId: userId,
      userType: userType,
      regionId: regionId,
      sessionId: sessionId,
      csrftoken: csrftoken,
      userName: userName,
      userTypes: userTypes,
      groups: groups,
    });

    console.log('STATE', this.state);
  };

  logout = () => {
    // set django session cookies
    const cookiesConfig = {
      path: "/",
      domain: "enccrv.cl",
      secure: false,
      sameSite: "Lax",
    };

    Cookies.remove("sessionid", cookiesConfig);
    Cookies.remove("csrftoken", cookiesConfig);

    localStorage.clear();
    this.setState({
      token: null,
      userId: null,
      userType: null,
      regionId: null,
      sessionId: null,
      csrftoken: null,
      userName: null,
      userTypes: null,
      groups: null,
    });
  };

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <AuthContext.Provider
            value={{
              token: this.state.token,
              userId: this.state.userId,
              userType: this.state.userType,
              regionId: this.state.regionId,
              sessionId: this.state.sessionId,
              csrftoken: this.state.csrftoken,
              userName: this.state.userName,
              userTypes: this.state.userTypes,
              groups: this.state.groups,
              login: this.login,
              logout: this.logout,
            }}
          >
            <Switch>
              {!this.state.token && (
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
              )}
              <Route
                exact
                path="/reset-password"
                name="Reset Password"
                render={(props) => <ResetPassword {...props} />}
              />
              <Route
                path="/"
                name="Home"
                render={(props) => <DefaultLayout {...props} />}
              />
              {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} /> */}
              {/* <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} /> */}
            </Switch>
          </AuthContext.Provider>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
