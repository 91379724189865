import React from "react";

export default React.createContext({
  token: null,
  userId: null,
  userType: null,
  regionId: null,
  sessionId: null,
  csrftoken: null,
  userName: null,
  userTypes: null,
  login: (
    token,
    userId,
    timeExp,
    userType,
    regionId,
    sessionId,
    csrftoken,
    userName,
    userTypes
  ) => {},
  logout: () => {},
});
